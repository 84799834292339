import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

import { ChakraBaseProvider, extendBaseTheme } from '@chakra-ui/react';
import { baseTheme } from '@chakra-ui/theme'; // Используем правильный импорт

// Расширяем базовую тему (можешь оставить как есть или настроить)
const theme = extendBaseTheme({
  components: baseTheme.components, // Используем компоненты из базовой темы
  styles: baseTheme.styles,         // Используем стили из базовой темы
  colors: baseTheme.colors,         // Используем цвета из базовой темы
  fonts: baseTheme.fonts,           // Используем шрифты из базовой темы
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Router>
    <ChakraBaseProvider theme={theme}> 
      <App />
    </ChakraBaseProvider>
  </Router>
);
