import React, { useState, useEffect, useRef } from 'react';
import * as api from '../../api.js';
import { SpinnerDotted } from 'spinners-react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


function MyPacksYandex() {

    function nDelPack() { return toast.info("Пак успешно удален!") }
    function nErr() { return toast.error("Ошибка") }

    const [isLoading, setIsLoading] = useState(false)
    const [myPacks, setMyPacks] = useState([])
    const [newCreate, isNewCreate] = useState(false)
    const [selectedImg, setSelectedImg] = useState(NaN)
    const [packName, setPackName] = useState('')
    const [packDesc, setPackDesc] = useState('')
    const navigate = useNavigate();

    const [deleteMode, setDeleteMode] = useState(false)

    useEffect(() => {
        getMyPacks()
    }, []);


    function getMyPacks() {
        setIsLoading(true);
        api.get(api.packs)
            .then((response) => {
                return response
            })
            .then(packData => {
                setMyPacks(packData);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            });
    }

    function handleChange(item) {
        if (item.id === 'pack_name') {
            setPackName(item.value)
        } else if (item.id === 'pack_description') {
            setPackDesc(item.value)
        }
    }

    function send() {
        var body = {
            pack_name: packName,
            comment: packDesc,
        }
        setIsLoading(true)
        api.post(api.pack(''), body)
            .then(packData => {
                getMyPacks()
                setIsLoading(false)
            })
            .catch(error => {
                setIsLoading(false)
            });
    }

    const ref = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                isNewCreate(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const deletePack = (e, pack) => {
        e.preventDefault()
        api.del(api.pack(pack.pack_id))
            .then(packData => {
                getMyPacks()
                nDelPack()
            })
            .catch(error => {
                setIsLoading(false)
            });
    }

    return (
        <div style={{ position: 'relative', minHeight: "400px" }}>
            <ToastContainer autoClose={3000} />
            <div className='firstBlock'>
                <h3>Мои подборки</h3>

            </div>
            {isLoading ?
                <div className='spinnerDiv'>
                    <SpinnerDotted
                        size="100px"
                        style={{ color: "rgb(255, 255, 255)" }}
                        color='#356dc2'
                        // secondaryColor='#356dc2'
                        className="spinner" />
                </div>
                :
                <>
                    <div style={newCreate ? { display: 'flex', gap: '10px', opacity: '.5', userSelect: "none", pointerEvents: "none" } : { display: 'flex', gap: '10px' }}>
                        <div
                            className='btn'
                            onClick={() => { isNewCreate(true) }}
                            style={{ marginTop: '5px', height: '32px', maxWidth: '230px' }}
                        >Создать подборку</div>
                        <div
                            className='btn alt-err-btn'
                            onClick={() => { setDeleteMode(!deleteMode) }}
                            style={{ marginTop: '5px', height: '32px', maxWidth: '230px' }}
                        >{!deleteMode ? "Удалить подборку" : "Отмена"}</div>
                    </div>
                    {myPacks.length > 0 ?
                        <div style={newCreate ? { opacity: '.5', userSelect: "none", pointerEvents: "none" } : {}} className='mp-pack-list'>
                            {myPacks.map((pack, index) => (
                                <div
                                    className={`mp-one-pack colorPack-${index} ${deleteMode ? 'wiggleAnim' : ''}`}
                                    key={index}
                                    onClick={(e) => !deleteMode ? (navigate(`/yandex/my-packs/${pack.pack_id}`)) : {}}
                                >
                                    <div className='mp-title-img'>
                                        <div>
                                            {deleteMode ? <span onClick={(e) => {
                                                e.stopPropagation();
                                                deletePack(e, pack);
                                            }} className='deletepack-btn'>-</span> : ''}
                                            <p className='mp-text' >{pack.title}</p>
                                            <div className='mp-description'>{pack.description}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        : <div style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "center",
                            width: '500px',
                            margin: '0 auto',
                            marginTop: '150px',
                            marginBottom: '150px',
                            opacity: newCreate && !deleteMode ? '.5' : "1",
                            userSelect: newCreate && !deleteMode ? 'none' : "auto",
                            pointerEvents: newCreate && !deleteMode ? 'none' : "auto",

                        }}>Паков не найдено, хотите
                            <p
                                className='mp-link'
                                onClick={() => { isNewCreate(true) }}>создать новый
                            </p>?</div>}
                    {/* <div onClick={() => { isNewCreate(true) }} className={`mp-one-pack add-pack ${newCreate ? 'mpop-hover' : ''}`} >
                        <div className='mp-title-img new-pls-pack'>
                            <div className=''>
                                <img src='/elements/plus.svg' alt='' className='pls-img' />
                            </div>
                        </div>
                    </div> */}
                    {newCreate ?
                        <div ref={ref} className='mp-inputs'>
                            <h2>Создание новой подборки</h2>
                            <input
                                onChange={(e) => handleChange(e.target)}
                                id='pack_name'
                                placeholder='Название подборки'
                                style={{ marginTop: '5px', height: '32px', minWidth: "250px" }}
                                className='sinput222'
                                type='text'
                            />
                            <input onChange={(e) => handleChange(e.target)}
                                id='pack_description'
                                placeholder='Описание'
                                style={{ marginTop: '5px', height: '32px', minWidth: "120px" }}
                                className='sinput222'
                                type='text'
                            />
                            <div
                                className='btn'
                                onClick={() => { send() }}
                                style={{ marginTop: '5px', height: '32px' }}
                            >Создать</div>
                        </div>
                        : ''}
                </>
            }
        </div>
    )
}

export default MyPacksYandex


