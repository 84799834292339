import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// import { SpinnerDotted } from 'spinners-react';
import { ResponsiveLine } from '@nivo/line';
// import Modal from "react-modal";
import { AiFillLike } from "react-icons/ai";
import { FaCalendarAlt } from "react-icons/fa";
import { FaMicrophone } from "react-icons/fa6";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { RiFileMusicFill } from "react-icons/ri";
import { BsPeopleFill } from "react-icons/bs";
import { SiApplemusic } from "react-icons/si";
import { GiMusicalNotes } from "react-icons/gi";

import * as api from '../../../api.js';

const OpenedYandexArtistCard = () => {
  const { artistId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [artistData, setArtistData] = useState({})
  const [artistMetrics, setArtistMetrics] = useState([])



  useEffect(() => {
    if (artistId) {
      getArtistList(artistId);
      getArtistMetrics(artistId)
    }
  }, [artistId]);

  const getArtistList = (artistId) => {
    setIsLoading(true);
    api.get(api.getStateMetricks(artistId))
      .then(response => {
        setArtistData(response)
      })
      .catch(error => {
        setIsLoading(false);
      });
  };

  const getArtistMetrics = (artistId) => {
    api.get(api.getArtistMetricks(artistId))
      .then(response => {
        const formattedMetrics = response.metrics.map(metric => ({
          date: metric.date,
          listeners_count: metric.listeners_count,
          rating: metric.rating
        }));

        setArtistMetrics(formattedMetrics);
      })
      .catch(error => {
        console.error("Ошибка при загрузке метрик:", error);
      });
  };
  function getGenreTranslate(genre) {
    const genreTranslations = {
      "rnb": {
        "name": "rnb",
        "ruName": "Ритм-н-блюз"
      },
      "folk": {
        "name": "folk",
        "ruName": "Фолк"
      },
      "pop": {
        "name": "pop",
        "ruName": "Поп"
      },
      "rock": {
        "name": "rock",
        "ruName": "Рок"
      },
      "classical": {
        "name": "classical",
        "ruName": "Классическая музыка"
      },
      "latinfolk": {
        "name": "latinfolk",
        "ruName": "Латиноамериканский фолк"
      },
      "prog": {
        "name": "prog",
        "ruName": "Прогрессив"
      },
      "funk": {
        "name": "funk",
        "ruName": "Фанк"
      },
      "rnr": {
        "name": "rnr",
        "ruName": "Рок-н-ролл"
      },
      "alternative": {
        "name": "alternative",
        "ruName": "Альтернатива"
      },
      "jazz": {
        "name": "jazz",
        "ruName": "Джаз"
      },
      "holiday": {
        "name": "holiday",
        "ruName": "Праздничная музыка"
      },
      "estrada": {
        "name": "estrada",
        "ruName": "Эстрада"
      },
      "balkan": {
        "name": "balkan",
        "ruName": "Балканская музыка"
      },
      "rap": {
        "name": "rap",
        "ruName": "Рэп"
      },
      "electronics": {
        "name": "electronics",
        "ruName": "Электронная музыка"
      },
      "soul": {
        "name": "soul",
        "ruName": "Соул"
      },
      "eastern": {
        "name": "eastern",
        "ruName": "Восточная музыка"
      },
      "dance": {
        "name": "dance",
        "ruName": "Танцевальная музыка"
      },
      "bollywood": {
        "name": "bollywood",
        "ruName": "Болливудская музыка"
      },
      "hardrock": {
        "name": "hardrock",
        "ruName": "Тяжелый рок"
      },
      "african": {
        "name": "african",
        "ruName": "Африканская музыка"
      },
      "country": {
        "name": "country",
        "ruName": "Кантри"
      },
      "soundtrack": {
        "name": "soundtrack",
        "ruName": "Саундтрек"
      },
      "relax": {
        "name": "relax",
        "ruName": "Релакс"
      },
      "blues": {
        "name": "blues",
        "ruName": "Блюз"
      },
      "experimental": {
        "name": "experimental",
        "ruName": "Экспериментальная музыка"
      },
      "reggae": {
        "name": "reggae",
        "ruName": "Регги"
      },
      "vocal": {
        "name": "vocal",
        "ruName": "Вокал"
      },
      "ska": {
        "name": "ska",
        "ruName": "Ска"
      },
      "disco": {
        "name": "disco",
        "ruName": "Диско"
      },
      "tradjazz": {
        "name": "tradjazz",
        "ruName": "Традиционный джаз"
      },
      "conjazz": {
        "name": "conjazz",
        "ruName": "Концертный джаз"
      },
      "epicmetal": {
        "name": "epicmetal",
        "ruName": "Эпический метал"
      },
      "smoothjazz": {
        "name": "smoothjazz",
        "ruName": "Смуз джаз"
      },
      "latinfolk": {
        "name": "latinfolk",
        "ruName": "Латиноамериканский фолк"
      },
      "progmetal": {
        "name": "progmetal",
        "ruName": "Прогрессивный метал"
      },
      "trance": {
        "name": "trance",
        "ruName": "Транс"
      },
      "turkishrap": {
        "name": "turkishrap",
        "ruName": "Турецкий рэп"
      },
      "newage": {
        "name": "newage",
        "ruName": "Нью-эйдж"
      },
      "georgian": {
        "name": "georgian",
        "ruName": "Грузинская музыка"
      },
      "rusrap": {
        "name": "rusrap",
        "ruName": "Русский рэп"
      },
      "ruspop": {
        "name": "ruspop",
        "ruName": "Русский поп"
      },
      "children": {
        "name": "children",
        "ruName": "Детская музыка"
      },
      "techno": {
        "name": "techno",
        "ruName": "Техно"
      },
      "metal": {
        "name": "metal",
        "ruName": "Метал"
      },
      "anime": {
        "name": "anime",
        "ruName": "Аниме музыка"
      },
      "vocaljazz": {
        "name": "vocaljazz",
        "ruName": "Вокальный джаз"
      },
      "bigbands": {
        "name": "bigbands",
        "ruName": "Большие оркестры"
      },
      "musical": {
        "name": "musical",
        "ruName": "Мюзикл"
      },
      "house": {
        "name": "house",
        "ruName": "Хаус"
      },
      "dnb": {
        "name": "dnb",
        "ruName": "Драм-н-бэйс"
      },
      "ambient": {
        "name": "ambient",
        "ruName": "Эмбиент"
      },
      "punk": {
        "name": "punk",
        "ruName": "Панк"
      },
      "dub": {
        "name": "dub",
        "ruName": "Даб"
      },
      "arabicpop": {
        "name": "arabicpop",
        "ruName": "Арабская поп-музыка"
      },
      "tvseries": {
        "name": "tvseries",
        "ruName": "Музыка из сериалов"
      },
      "numetal": {
        "name": "numetal",
        "ruName": "Ню-метал"
      },
      "classicalmasterpieces": {
        "name": "classicalmasterpieces",
        "ruName": "Классические шедевры"
      },
      "reggaeton": {
        "name": "reggaeton",
        "ruName": "Реггетон"
      },
      "foreignbard": {
        "name": "foreignbard",
        "ruName": "Иностранный бард"
      },
      "caucasian": {
        "name": "caucasian",
        "ruName": "Кавказская музыка"
      },
      "postrock": {
        "name": "postrock",
        "ruName": "Пост-рок"
      },
      "african": {
        "name": "african",
        "ruName": "Африканская музыка"
      },
      "tatar": {
        "name": "tatar",
        "ruName": "Татарская музыка"
      },
      "balkan": {
        "name": "balkan",
        "ruName": "Балканская музыка"
      },
      "rnr": {
        "name": "rnr",
        "ruName": "Рок-н-ролл"
      },
      "industrial": {
        "name": "industrial",
        "ruName": "Индастриал"
      }
    };
    if (genreTranslations[genre]) {
      return genreTranslations[genre].ruName
    } else {
      return genre
    }
  }

  const [IsReverse, setIsReverse] = useState(true)

  const theme = {
    crosshair: {
      line: {
        stroke: '#fff', // Цвет crosshair
        strokeWidth: 2, // Толщина линии crosshair
        strokeOpacity: 0.75, // Прозрачность линии crosshair
      }
    },
    grid: {
      line: {
        stroke: 'rgba(0, 0, 0, 1)', // Цвет линий сетки
        strokeWidth: 0.4,
      }
    },
    background: 'var(--c_sddd)', // Цвет фона графика
    axis: {
      ticks: {
        line: {
          stroke: '#777777', // Цвет линий меток осей
          strokeWidth: 1,
        },
        text: {
          fill: '#333333', // Цвет текста меток осей
        },
      },
    },
  };

  return (
    <div className='artistcard-block'>
      <div className='ab-header'>
        <div className='btn' onClick={() => navigate('/')} ><FaArrowAltCircleLeft className='back-ico' />Назад</div>
      </div>
      <div className='artist-info'>
        {artistData.avatar_url ? <img className='artist-img' src={artistData.avatar_url} alt='' /> : <LoadingImg />}
        <div className='artist-text-info'>
          <label className='artist-name'>{artistData.artist_name ? artistData.artist_name : <LoadingLine />}</label>
          <div className='genres-list'>
            {artistData.genres ? artistData.genres.map((genre, index) => (
              <div key={index} className='genre'>{getGenreTranslate(genre)}</div>
            )) : <LoadingLine />
            }
          </div>
        </div>

        <div className='total-listens'>
          <label>Всего лайков:</label>
          <div className='totalIco'>{artistData.likes_count ? artistData.likes_count.toLocaleString('ru-RU') : <LoadingLine />}</div>
        </div>
      </div>
      <div style={{ marginTop: '20px', marginBottom: "10px" }}>
        {artistData.links && artistData.links.length > 0 ? <h2 className="h-social" >Ссылки на социальные сети</h2> : ''}
        <div className='socialList'>
          {artistData.links ? artistData.links.map((link, index) => (
            <div onClick={() => window.open(link.href, '_blank')} key={index} className='social-icon'>
              <img className='social-img' src={(link.href.replace(link.title, '')).replace('?', '') + 'favicon.ico'} alt='' />
              <p>{link.socialNetwork}</p>
            </div>
          ))
            :
            <LoadingLine />
          }
        </div>
      </div>
      <h2 style={{ color: "var(--c5)", fontSize: "25px", fontWeight: 600, marginBottom: '-10px' }}>Динамика слушателей</h2>
      <div className="respCont" style={{ height: 400, padding: '20px 20px 20px 0px', "& div": { overflow: "hidden", borderRadius: "25px", } }}>
        <ResponsiveLine
          data={[
            {
              id: "Слушатели",
              data: artistMetrics.map(metric => ({
                x: new Date(metric.date),
                y: metric.listeners_count
              }))
            }
          ]}
          theme={theme}
          colors={['#3379E2']}
          margin={{ top: 50, right: 10, bottom: 50, left: 60 }}
          xScale={{ type: 'time', format: 'native', precision: 'day' }}
          yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
          enableGridX={false}
          enableGridY={true}
          crosshairType={'bottom-left'}
          xFormat=">-.2s"  // Форматирование оси X
          yFormat=">-.2s"  // Форматирование оси Y
          axisLeft={{
            format: ">-.2s",
            legend: 'Слушатели',
            legendOffset: -55,
            legendPosition: 'middle',
            tickPadding: 10,
            tickSize: 0,
          }}

          axisBottom={{
            format: '%d.%m.%Y',
            tickValues: 'every 10 days',
            legend: '',
            legendOffset: 1,
            legendPosition: 'middle',
          }}
          enableSlices='x'
          enableTouchCrosshair={true}
          useMesh={true}
          sliceTooltip={({ slice }) => slice.points.map((point, index) => (
            <div className='tooltipMap' key={index}>
              <div style={{ width: '20px', height: "20px", backgroundColor: point.color, borderRadius: "5px" }} />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className='tooltip-metric'>
                  <label>Дата:</label>
                  <p className='ttm-metric'>{new Date(point.data.x).toLocaleDateString('ru-RU')}</p>
                </div>
                <div className='tooltip-metric'>
                  <label>{point.serieId}:</label>
                  <p className='ttm-metric'>{point.data.y.toLocaleString('en-EN')}</p>
                </div>
              </div>
            </div>
          ))}
          enableArea={false}
        />
      </div>
      <h2 style={{ color: "var(--c5)", fontSize: "25px", fontWeight: 600, marginBottom: '-10px' }}>Динамика рейтинга</h2>
      <div className="respCont" style={{ height: 400, padding: '20px 20px 20px 0px', }}>
        <ResponsiveLine
          data={[
            {
              id: "Рейтинг",
              data: artistMetrics.map(metric => ({
                x: new Date(metric.date),
                y: metric.rating
              }))
            }
          ]}
          theme={theme}
          margin={{ top: 50, right: 10, bottom: 50, left: 60 }}
          xScale={{ type: 'time', format: 'native', precision: 'day' }}
          yScale={{ type: 'linear', min: 'auto', max: 'auto', reverse: IsReverse }}
          enableCrosshair={true}
          crosshairType="bottom-left"
          axisLeft={{
            legend: 'Рейтинг',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          axisBottom={{
            format: '%d.%m.%Y',
            tickValues: 'every 14 days',
            legend: '',
            legendOffset: 36,
            legendPosition: 'middle',
          }}
          colors={['#61cdbb']}
          enableSlices="x"
          useMesh={true}
          sliceTooltip={({ slice }) => slice.points.map((point, index) => (
            <div className='tooltipMap' key={index}>
              <div style={{ width: '20px', height: "20px", backgroundColor: point.color, borderRadius: "5px" }} />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className='tooltip-metric'>
                  <label>Дата:</label>
                  <p className='ttm-metric'>{new Date(point.data.x).toLocaleDateString('ru-RU')}</p>
                </div>
                <div className='tooltip-metric'>
                  <label>{point.serieId}:</label>
                  <p className='ttm-metric'>{point.data.y.toLocaleString('en-EN')}</p>
                </div>
              </div>
            </div>
          ))}
          enableArea={false}

        />
      </div>
      {/* <div onClick={() => setIsReverse(!IsReverse)} className='btn'>ГучиФлипФлап</div> */}
      <div className='artist-releases'>
        <h2 style={{ color: "var(--c5)", fontSize: "25px", fontWeight: "600", }}>Релизы</h2>
        <div className='header-line release-line'>
          <div className='release-cell release-cell-title'>Название релиза</div>
          <div className='release-cell'><FaMicrophone className='rc-ico' />Тип</div>
          <div className='release-cell'><BsPeopleFill className='rc-ico' />Лейбл</div>
          <div className='release-cell'><FaCalendarAlt className='rc-ico' />Дата</div>
          <div className='release-cell last-cell'><AiFillLike className='rc-ico' />Лайки</div>
          <div className='release-cell ico-cell'></div>
        </div>
        {artistData.releases ? artistData.releases
          .sort((a, b) => new Date(b.release_date) - new Date(a.release_date)) // Сортировка по дате
          .map((release, index) => (
            <div className='release-line' onClick={() => { window.open(`https://music.yandex.ru/album/${release.id}`) }} key={index}>
              <div className='release-cell release-cell-title'>{release.title} </div>
              <div className='release-cell'><FaMicrophone className='rc-ico' />{release.type === 'single' ? 'Сингл' : "Альбом"} </div>
              <div className='release-cell'><BsPeopleFill className='rc-ico' />{release.labels[0]} </div>
              <div className='release-cell'><FaCalendarAlt className='rc-ico' />{(new Date(release.release_date)).toLocaleDateString('ru-RU')} </div>
              <div className='release-cell last-cell'><AiFillLike className='rc-ico' />{release.likes_count} </div>
              <div className='release-cell ico-cell'><FaExternalLinkAlt /></div>
            </div>
          ))
          :
          <>
            <div className='release-line'><LoadingLine /></div>
            <div className='release-line'><LoadingLine /></div>
            <div className='release-line'><LoadingLine /></div>
            <div className='release-line'><LoadingLine /></div>
            <div className='release-line'><LoadingLine /></div>
          </>
        }
        <div style={{ display: 'flex', alignItems: "stretch", justifyContent: "stretch", gap: "30px" }}>
          <div className='card-art-info' style={{ display: 'flex', flexDirection: "column", alignItems: "stretch", justifyContent: "stretch", width: '50%' }}>
            <h2 style={{ fontSize: "25px", fontWeight: "600" }}>Плейлисты</h2>
            <h3>В которых есть треки этого артиста</h3>
            {artistData ? artistData.playlists && artistData.playlists.length > 0 ? (artistData.playlists.map((playlist, index) => (
              <div className='release-line' style={{ cursot: "auto" }} key={index}>
                <div className='release-cell release-cell-title'>{playlist.title} </div>
                <div className='release-cell'><RiFileMusicFill className='rc-ico' />{playlist.track_count} </div>
                {/* <div className='release-cell last-cell'><AiFillLike className='rc-ico' />{playlist.likes_count} </div> */}
                {/* <div className='release-cell ico-cell'><FaExternalLinkAlt /></div> */}
              </div>
            ))) :
              <div className='release-line'>Плейлистов не найдено</div>
              :
              <>
                <div className='release-line'><LoadingLine /></div>
                <div className='release-line'><LoadingLine /></div>
                <div className='release-line'><LoadingLine /></div>
                <div className='release-line'><LoadingLine /></div>
                <div className='release-line'><LoadingLine /></div>
              </>
            }
            <GiMusicalNotes className="musicCardIcon" />
          </div>
          <div className='card-art-info' style={{ overflow: "hidden", position: "relative", display: 'flex', flexDirection: "column", alignItems: "stretch", justifyContent: "stretch", width: '50%' }}>
            <h2 style={{ fontSize: "25px", fontWeight: "600", }}>Лейблы</h2>
            <h3>Лейблы на которые подписан/был подписан артист</h3>
            {artistData ? artistData.labels && artistData.labels.length > 0 ? (artistData.labels.map((label, index) => (
              <div className='release-line' style={{ cursot: "auto" }} key={index}>
                <div className='release-cell release-cell-title'>{label.name} </div>

              </div>
            )))
              :
              <div className='release-line'>Лейблов не найдено</div>
              :
              <>
                <div className='release-line'><LoadingLine /></div>
                <div className='release-line'><LoadingLine /></div>
                <div className='release-line'><LoadingLine /></div>
                <div className='release-line'><LoadingLine /></div>
                <div className='release-line'><LoadingLine /></div>
              </>
            }
            <SiApplemusic className="musicCardIcon" />
          </div>
        </div>
      </div>

    </div>
  );
};

export default OpenedYandexArtistCard;


const LoadingLine = () => {
  return (
    <div className='line-loading'></div>
  )
}

const LoadingImg = ({ minHei = false }) => {
  return (
    <div style={{ minHeight: minHei ? minHei : '' }} className='img-loading'></div>
  )
}
