import React, { useState } from 'react';


const Profile = () => {

    return (
        <div className='workspace'>

        </div>
    );
};

export default Profile;