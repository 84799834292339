import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { SpinnerDotted } from 'spinners-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  AreaChart,
  Area,
  ResponsiveContainer,
} from 'recharts';
import Modal from "react-modal";
import * as api from '../../../api.js';

const OpenedArtistCard = () => {
  const { artistName } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [artistData, setArtistData] = useState(null);
  const [additionalData, setAdditionalData] = useState(null);
  const [first_date, setFirst_date] = useState('2019-01-01');
  const [second_date, setSecond_date] = useState('2039-01-01');
  const [brushStartIndex, setBrushStartIndex] = useState(0);
  const [brushEndIndex, setBrushEndIndex] = useState(0);

  useEffect(() => {
    if (artistName) {
      getArtistList(artistName);
    }
  }, [artistName]);

  const getArtistList = (artistName) => {
    setIsLoading(true);
    const body = {
      artist_name: artistName,
      first_date: first_date,
      second_date: second_date,
    };

    api.post(`${api.get_artist_info}`, body)
      .then(response => {
        const totalAdditions = response.shift(); // Удаляем первый объект и сохраняем его
        setArtistData(response);
        setAdditionalData(totalAdditions);
        setIsLoading(false);
        const midIndex = Math.floor(response.length / 2);
        const range = Math.floor(response.length / 4);
        setBrushStartIndex(midIndex - range);
        setBrushEndIndex(midIndex + range);
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error fetching artist list:', error);
      });
  };

  const getArtistListByDay = (artistName) => {
    if (!artistName) {
      console.error('Artist name is undefined');
      return;
    }

    setIsLoading(true);
    const body = {
      artist_name: artistName,
      first_date: first_date,
      second_date: second_date,
    };

    api.post(`${api.get_artist_info_day}`, body)
      .then(response => {
        const totalAdditions = response.shift(); // Удаляем первый объект и сохраняем его
        setArtistData(response);
        setAdditionalData(totalAdditions);
        setIsLoading(false);

        // Установка Brush на середину
        const midIndex = Math.floor(response.length / 2);
        const range = Math.floor(response.length / 4);
        setBrushStartIndex(midIndex - range);
        setBrushEndIndex(midIndex + range);
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error fetching daily artist list:', error);
      });
  };

  const formatYAxis = (tickItem) => {
    if (tickItem >= 1000) {
      return `${tickItem / 1000}K`;
    }
    return tickItem;
  };

  const formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    return date.getDate() === 1 ? `${date.getDate()}.${date.getMonth() + 1}\n${date.getFullYear()}` : '';
  };

  const divStyles = {

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    transition: 'none',
    padding: '20px',
    borderRadius: '15px',
    border: 'solid 2px white',
    background: 'rgba(0,0,0,0)'

  }
  const [selectedButton, setSelectedButton] = useState('allperiod');
  const handleAllPeriodClick = () => {
    setSelectedButton('allperiod');
    getArtistList(artistName);
  };

  const handlePerDayClick = () => {
    setSelectedButton('perday');
    getArtistListByDay(artistName);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className='artistcard-block'>
      <div className='ab-backbtn'>
        <div className='HomeBtn' style={{ width: '150px' }} onClick={() => { navigate('/') }}>Назад</div>
        <div className='btns-swtich'>
          {/* <div
            className="HomeBtn allperiod"
            style={{
              width: '150px',
              color: selectedButton === 'allperiod' ? 'white' : 'black',
              backgroundColor: selectedButton === 'allperiod' ? '#356dc2' : 'lightgray',
              border: '2px solid #356dc2'
            }}
            onClick={handleAllPeriodClick}
          >
            За весь период
          </div> */}
          {/* <div
            className="HomeBtn perday"
            style={{
              width: '150px',
              color: selectedButton === 'perday' ? 'white' : 'black',
              backgroundColor: selectedButton === 'perday' ? '#356dc2' : 'lightgray',
              border: '2px solid #356dc2'
            }}
            onClick={handlePerDayClick}
          >
            За день
          </div> */}
        </div>
      </div>
      <div className='ab-header'>
        <div className='ab-artist-name'>
          <div className='arti-name'>{artistName}</div>
          <div className='total-addict'>{additionalData ? `Добавления: ${additionalData.total_additions}` : 'Загрузка...'}</div>
        </div>
      </div>
      {/* <div onClick={() => { setIsModalOpen(true) }}>ПАНИМАЮ</div> */}
      <div className='ab-main-info'>
        {isLoading ? (
          <SpinnerDotted
            size="100px"
            style={{ color: "#356dc2" }}
            color='#356dc2'
            className="spinner" />
        ) : (
          <>
            <div style={divStyles} >
              <AreaChart
                width={750}
                height={500}
                data={artistData}
                syncId="anyId"
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                {/* <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor="rgb(255, 15, 130)"
                      stopOpacity={0.8} />
                    <stop
                      offset="95%"
                      stopColor="rgb(255, 183, 76)"
                      stopOpacity={0.8} />
                  </linearGradient>
                </defs> */}
                <XAxis
                  dataKey="date"
                  tickFormatter={formatXAxis} />
                <YAxis tickFormatter={formatYAxis} />
                <Brush
                  dataKey="date"
                  height={30}
                  stroke="rgba(53, 109, 194,1)"
                  fill="white"
                  tick={{ fill: 'white' }}
                  startIndex={brushStartIndex}
                  endIndex={brushEndIndex}
                />
                <Tooltip
                  contentStyle={{
                    borderRadius: "15px",
                    background: "white",
                    padding: '10px'
                  }} />
                <Area
                  type="monotone"
                  dataKey="count"
                  name="Добавления"
                  stroke="rgba(53, 109, 194, 1)"
                  fillOpacity={1}
                  fill="rgba(53, 109, 194, 0.5)"
                />
              </AreaChart>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OpenedArtistCard;
