import React from 'react'

function NewBackground() {
    return (
        <div className='bg-111'>
            <img className='bg-static-tst' src='/Background 1.png' alt='' />
        </div>
    )
}

export default NewBackground