import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as api from "../../api.js";

function ProfileDD() {
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [isDroppedDown, setIsDroppedDown] = useState(false);

  const dropDownRef = useRef(null);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    api
      .get(api.get_username, token)
      .then((response) => {
        return response;
      })
      .then((data) => {
        setUsername(data);
      })
      .catch((error) => {
        navigate("/login");
      });
  }, [navigate]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setIsDroppedDown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div
      onClick={() => setIsDroppedDown(!isDroppedDown)}
      className="profile mEl"
      style={{ backgroundColor: isDroppedDown ? "var(--c7)" : "" }}
    >
      <img className="Ava" src="/elements/avatar.png" alt="avatar" />
      <div className="UserName">
        {username ? username : <div className="line-loading"></div>}
      </div>
      {isDroppedDown ? (
        <div ref={dropDownRef} className="dropdown">
          <div className="dropdown_menu">
            <div className="dd_item" onClick={handleLogout}>
              Выход
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ProfileDD;
