/* eslint-disable */
import React, { useState } from 'react';
// import Background from '../BG/background.js';
import LoginForm from './LoginForma.js';
import NewBackground from '../BG/new_background.js';
// import './login.css';
import { useNavigate } from "react-router-dom"
import * as api from '../api.js';

async function fetchData(route, method, body = null) {
    const token = localStorage.getItem('token');
    const options = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
    if (body) {
        options.body = JSON.stringify(body);
    }
    try {
        const response = await fetch(route, options);
        const data = await response.json();
        return data;
    } catch (error) {
        return error
    }
}



const LoginBlock = ({ onAuthenticate }) => {
    const [user_name, setUserName] = useState("");
    const [password, setPassword] = useState('');
    const [LoginError, setLoginError] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    function handleSubmit(event) {
        event.preventDefault();
        setLoginError('');
        setLoading(true)
        api.post(`${api.login}`, { username: user_name, password: password }, false)
            .then(data => {
                if (data.access_token) {
                    localStorage.setItem('token', data.access_token);
                    sessionStorage.setItem('token', data.access_token);
                    var token = localStorage.getItem('token')
                    api.get(api.get_username, token)
                        .then(async response => {
                            if (response) {
                                navigate('/')
                            } else {
                                setLoading(false)
                                setLoginError('Произошла ошибка');
                            }
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                } else {
                    setLoading(false)
                    setLoginError('Неверный логин или пароль');
                }
            })
            .catch(error => {
                setLoading(false)
                setLoginError('Неверный логин или пароль');
            });
    }

    return (
        <div className='mainDiv'>
            <div className={`loginBlock ${loading ? 'loading-inactive' : ''}`}>
                <form className='loginForm' onSubmit={handleSubmit}>
                    <div className='Loger'>
                        {/* <img className='logo' src='/Logo-Elements/Logo_light.png' alt='logo' /> */}
                        <img className='logo' src='/Logo-Elements/logo2024.png' alt='logo' />
                    </div>
                    <input
                        className={`loginInput inplogin ${LoginError ? 'errLogin' : ''}`}
                        type="text"
                        placeholder='Имя пользователя'
                        value={user_name}
                        onChange={(e) => {
                            setUserName(e.target.value)
                            setLoginError(false)
                        }}
                    />
                    <input
                        placeholder='Пароль'
                        className={`passwordInput inplogin ${LoginError ? 'errLogin' : ''}`}
                        type="password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                            setLoginError(false)
                        }}
                    />
                    <div className='label-error'>{LoginError ? LoginError : ''}</div>
                    <button className='login_btn' type='submit'>
                        Войти
                    </button>
                </form>


            </div>
            <NewBackground />
        </div>
    );
};


export default LoginBlock;
