import React, { useState } from 'react';
import Select from 'react-select';
// import vklogo from './elements/icons/vklogo.svg';
import yamusic from './elements/icons/yamusic.svg';
// import vkmusic from './elements/icons/vkmusic.svg';
import { useNavigate } from 'react-router-dom';
import { FiPackage } from "react-icons/fi";

function Platform() {
    const navigate = useNavigate();
    const options = [
        // {
        //     value: 'vk-music',
        //     label: (
        //         <div className="dropdown-item">
        //             <img src={vkmusic} alt="VK Music" width={20} height={20} />
        //             ВК Музыка
        //         </div>
        //     ),
        //     onClick: () => navigate('/vk')
        // },
        {
            value: 'ya-music',
            label: (
                <div className="dropdown-item">
                    <img src={yamusic} alt="Yandex Music" width={20} height={20} />
                    Яндекс Музыка
                </div>
            ),
            onClick: () => navigate('/yandex')
        },
        {
            value: 'my-packs',
            label: (
                <div className="dropdown-item">
                    <FiPackage style={{width: "20px",height:"20px"}} />
                    Мои подборки
                </div>
            ),
            onClick: () => navigate('/yandex/my-packs/')
        }
    ];

    const [selectedOption, setSelectedOption] = useState(options[0]);

    const handleChange = (option) => {
        setSelectedOption(option);
        if (option && option.onClick) {
            option.onClick();
        }
    };

    return (
        <div className='platform-selector'>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px", boxSizing:"border-box" }}>
                {options.map((option, i) => (
                    <div className='Categories' onClick={option.onClick} key={i} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {option.label}
                    </div>
                ))}
                {/* <Select
                    isDisabled={true}
                    value={selectedOption}
                    onChange={handleChange}
                    options={options}
                    placeholder="Выберите платформу"
                    isSearchable={false}
                /> */}
            </div>
        </div>
    );
}

export default Platform;
