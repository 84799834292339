import Profile from '../components/medium/profile.jsx'
import { Switch } from '@headlessui/react'
import { useState, useEffect } from 'react';
import { IoMdSunny, IoMdMoon } from "react-icons/io";

const Header = () => {

    const [isDarkMode, setIsDarkMode] = useState(true)


    useEffect(() => {
        const rootElement = document.getElementById('bodyID');
        if (isDarkMode) {
            rootElement.className = 'dark'
        } else {
            rootElement.className = 'light'
        }
    }, [isDarkMode])



    return (
        <div className='header'>

            <div className='LeftSide'>
                <img alt='' className='logo header_logo' src='/Logo-Elements/logo2024.png'></img>
                <p className='text_logo'>GM-Analytics</p>
            </div>
            <div className='RightSide'>
                <div className='darkThemeSwitch' style={{ justifyContent: isDarkMode ? "start" : "end" }} onClick={() => setIsDarkMode(!isDarkMode)}>
                    <span className='circle'>
                        {isDarkMode ? <IoMdMoon color={isDarkMode ? "white" : "black"} /> : <IoMdSunny color={isDarkMode ? "white" : "black"} />}
                    </span >
                </div>
                <Profile />
            </div>
        </div>
    );
};

export default Header;


