import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import * as api from '../../api.js';
import { SpinnerDotted } from 'spinners-react'
import { useTable } from 'react-table';
import { FaArrowAltCircleLeft } from "react-icons/fa";

import { FaRegTrashCan } from "react-icons/fa6";

function MyUniquePackYandex() {
    const [isLoading, setIsLoading] = useState(true)
    const { pack_id } = useParams();
    const navigate = useNavigate();
    const [pack, setPack] = useState('')
    const [tableData, setTableData] = useState([])
    const [aprove, setAprove] = useState(false)

    const [packInfo, setPackInfo] = useState({ pack_name: "", pack_description: "" })

    const [data, setData] = useState([])

    useEffect(() => {
        uploadInfo()
    }, []);


    const deletePack = () => {
        api.del(api.pack(pack.pack_id))
            .then(packData => {
                navigate('/yandex/my-packs')
            })
            .catch(error => {
                setIsLoading(false)
            });
    }

    async function handleDeleteArtistFromPack(artistId) {
        setIsLoading(true)
        var body = [artistId]
        var url = api.pack(pack_id) + "/artists";
        setIsLoading(false)
        api.del(url, body)
            .then((response) => {
                uploadInfo()
            })
    }

    async function uploadInfo() {
        setIsLoading(true);
        if (pack_id) {
            await list_artist_by_pack()
        }
    }

    async function list_artist_by_pack() {
        const packData = await api.get(api.list_artist_by_pack_yandex(pack_id));
        if (packData.message) {

            return
        }
        setData(packData.artists);
        setPackInfo({ pack_name: packData.pack_name, pack_description: packData.pack_description })
        setIsLoading(false)
        return true;
    }


    const columns = useMemo(() => [
        // {
        //     Header: ' ',
        //     accessor: 'select',
        //     width: '40px',
        //     Cell: ({ row }) => (
        //         <label className='customCheckbox'>
        //             <input
        //                 id="checkbx"
        //                 type="checkbox"
        //                 // checked={markedRows.includes(row.original.artist_id)}
        //                 readOnly
        //             />
        //             <span></span>
        //         </label>
        //     ),
        // },
        {
            Header: 'Имя артиста',
            accessor: 'name',
            width: '410px',
        },
        {
            Header: 'Рейтинг',
            accessor: 'rating',
            width: '215px',
        },
        // {
        //     Header: 'Новые прослушивания',
        //     accessor: 'delta_listeners_count',
        // },
        {
            Header: 'Слушатели',
            accessor: 'listeners',
        },
        // {
        //     Header: 'Изменение рейтинга',
        //     accessor: 'delta_rating',
        // },
        // {
        //     Header: 'Лейбл',
        //     accessor: 'label_name',
        // },
        {
            Header: ' ',
            Cell: ({ row }) => (
                <div className='actions-div'>
                    <div className='btn' onClick={() => window.open(`/yandex/artist-card/${row.original.artist_id}`, '_blank')}>Подробнее</div>
                    <div className='btn alt-err-btn' onClick={() => handleDeleteArtistFromPack(row.original.artist_id)}><FaRegTrashCan /></div>
                </div>
            ),
            width: '150px',
        },
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
    } = useTable({
        columns,
        data,
        manualPagination: true,
    });



    return (
        <div className='mypacknum'>
            <div className='btn' style={{ maxWidth: "200px", marginBottom: "20px" }} onClick={() => navigate('/yandex/my-packs')} ><FaArrowAltCircleLeft className='back-ico' />Назад</div>
            <div style={{ marginBottom: "25px" }}>
                <h2 style={{ fontSize: "25px", fontWeight: "700" }}>{packInfo.pack_name ? packInfo.pack_name : ""}</h2>
                <h3>{packInfo.pack_description ? packInfo.pack_description : ""}</h3>
            </div>
            {
                aprove ?
                    <div className='background-popup'>
                        <div className='aprove-notify'>
                            Вы уверены, что хотите удалить подборку?
                            <div className='yep-no'>
                                <div onClick={() => { setAprove(false) }} className='btn alt-err-btn'>Нет</div>
                                <div onClick={() => { deletePack() }} className='btn'>Да, удалить</div>
                            </div>
                        </div>
                    </div>
                    :
                    ''
            }
            {
                isLoading ?
                    <div className='spinnerDiv'>
                        <SpinnerDotted
                            size="100px"
                            style={{ color: "#356dc2" }}
                            color='#356dc2'
                            className="spinner" />
                    </div>
                    :
                    <>
                        {/* <div className="inputsi secondInp">
                            <div className="range-slider">
                                <div className="min-Max">От:</div>
                                <h3 className="min-Max">Даты</h3>
                                <div className="min-Max">До:</div>
                            </div>
                            <div className='fkkkl'>
                                <input
                                    type="date"
                                    name="minValue"
                                    className="sinput222"
                                    value={timeRangeFrom.toLocaleString('ru-RU')}
                                    onChange={(e) => setTimeRangeFrom(e.target.value)}
                                />
                                <input
                                    type="date"
                                    name="maxValue"
                                    className="sinput222 sinput222_2"
                                    value={timeRangeTo.toLocaleString('ru-RU')}
                                    onChange={(e) => setTimeRangeTo(e.target.value)}
                                />
                            </div>
                        </div> */}
                        <div style={{ overflow: 'auto', minHeight: "500px", maxHeight: "500px", position: "relative" }}>
                            {data.length > 0 ? <table className={`main-table-block ${isLoading ? 'loading-inactive' : ''}`} {...getTableProps()} style={{ width: '100%', opacity: isLoading ? '40%' : '100%' }}>
                                <thead className='main-tableheader-block'>
                                    {headerGroups && headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th
                                                    className='header-row'
                                                    // onClick={() => isSorting ? handleSort(column.id) : ('')}
                                                    {...column.getHeaderProps()}>
                                                    <div className='flexBox'>
                                                        {column.render('Header')}
                                                        {/* {isSorting ? (orderSortMetric === column.id ? (isOrder ? <FaArrowDownShortWide /> : <FaArrowDownWideShort />) : "") : ''} */}
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {rows.map((row, index) => {
                                        prepareRow(row);
                                        return (
                                            <tr
                                                className={`row-main-block ${index % 2 === 0 ? 'background-colored-row' : ''}`}
                                                {...row.getRowProps()}>
                                                {row.cells.map((cell, index) => (
                                                    <td
                                                        style={{
                                                            borderRadius: index === 0 ? '5px 0 0 5px' : index === 7 ? '0 5px 5px 0' : '',
                                                            textAlign: [1, 2, 4, 5, 6].includes(index) ? 'center' : '',
                                                            padding: '5px 10px',
                                                        }}
                                                        {...cell.getCellProps()}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table> : <p className='notfound-text'> {!isLoading ? "Ничего не найдено" : ""}</p>}
                        </div>
                        <div className='btn err-btn' style={{ maxWidth: "200px" }} onClick={() => { setAprove(true) }}>Удалить подборку</div>
                    </>
            }
        </div>
    )
}

export default MyUniquePackYandex